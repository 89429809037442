import React from 'react';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';
import Seo from '../../components/seo';
import NewsContent from '../../components/NewsContent';
import Container from '../../components/Container';

const AllNews = ({ data }) => {
  const { allNewsPage, news } = camelizeKeys(data.strapi);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  const seo = allNewsPage && allNewsPage.seo;

  const { numberOfNews, backgroundText } = !isEmpty(allNewsPage) && allNewsPage;
  const isExist = !isEmpty(allNewsPage)
    && !isEmpty(allNewsPage.content)
    && allNewsPage.content.every((dynamic) => dynamic.typename !== 'STRAPI_ComponentSectionsNewsHero');

  const eventBannersBetweenNews = allNewsPage
    && allNewsPage.content
    && allNewsPage.content.filter(
      (c) => c.typename
        === 'STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews',
    );

  return (
    <Layout headerData={headerData} footerData={footerData} signUpData={signUpData}>
      {seo && (
        <Seo
          title={seo?.title}
          description={seo?.description}
        />
      )}
      {isExist
        && allNewsPage
        && !isEmpty(allNewsPage.content)
        && !isEmpty(data.news)
        && (
          <div className="all-news-container">
            <Container>
              <NewsContent
                content={allNewsPage.content}
                data={{ news, numberOfNews, backgroundText }}
                eventBannersBetweenNews={eventBannersBetweenNews}
              />
            </Container>
          </div>
        )}
      {allNewsPage && !isEmpty(allNewsPage.content) && (
        <DynamicZone
          eventBannersBetweenNews={eventBannersBetweenNews}
          components={allNewsPage.content}
          data={{ news, numberOfNews, backgroundText }}
        />
      )}
    </Layout>
  );
};

AllNews.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

AllNews.defaultProps = {
  data: {
    strapi: {},
  },
};

export default AllNews;

export const AllNewsQuery = graphql`
  query getAllNews {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      allNewsPage {
        id
        seo {
          description
          id
          title
        }
        numberOfNews
        content {
          ... on STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews {
            id
            afterNewsIndex
            mobileBackground {
              url
            }
            __typename
            data {
              bannerLinks {
                id
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  id
                  label
                  path
                  openInNewWindow
                }
              }
              event {
                id
                label
                data {
                  date
                  dateClock
                  dateDayOfWeek
                  dateMonth
                  dateTimezone
                  id
                  slug
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsNewsHero {
            id
            backgroundText
            __typename
            news {
              label
              data {
                id
                date
                title
                type {
                  label
                }
                primaryImage {
                  url
                }
                secondaryImage {
                  url
                }
                description
                slug
              }
            }
          }
          ... on STRAPI_ComponentSectionsNewsHeroBlack {
            id
            __typename
            newsBackgroundImage {
              url
            }
            bannerLinks {
              path
              openInNewWindow
              offerIdEverflow
              label
              id
              data {
                path
                label
                openInNewWindow
              }
            }
            relatedEvent {
              label
              data {
                id
                title
                slug
                description
                date
                dateClock
                dateDayOfWeek
                dateMonth
                dateTimezone
                image {
                  url
                }
                additionalNews {
                  label
                  data {
                    id
                    title
                    slug
                    type {
                      label
                    }
                    date
                    secondaryImage {
                      url
                    }
                    primaryImage {
                      url
                    }
                    commonImage {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      news {
        created_at
        date
        description
        isFeatured
        published_at
        slug
        title
        id
        primaryImage {
          url
        }
        secondaryImage {
          url
        }
        commonImage {
          url
        }
        type {
          label
        }
      }
    }
  }
`;
